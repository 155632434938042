/* Michi.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .contitle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .title {
    margin-top: 50px;
    color: white;
    font-size: 50px;
  }
  
  .title span {
    color: #26ffcb;
    padding-left: 10px;
  }
  
  .board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0;
  }
  
  .row1, .row2, .row3 {
    display: flex;
    justify-content: center;
  }
  
  .boxes {
    width: 180px;
    height: 180px;
    background: #ffffff;
    border: 4px solid #272727;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .reset-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .reset {
    width: 250px;
    height: 100px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50px;
    background: #1f3540;
    font-size: 36px;
    font-weight: bold;
    color: #26ffcb;
    margin-top: 25px;
    margin-bottom: 50px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .boxes {
      width: 120px;
      height: 120px;
    }
  
    .reset {
      width: 200px;
      height: 80px;
      font-size: 28px;
    }
  
    .title {
      font-size: 40px;
    }
  }
  
  @media (max-width: 480px) {
    .boxes {
      width: 90px;
      height: 90px;
    }
  
    .reset {
      width: 150px;
      height: 60px;
      font-size: 24px;
    }
  
    .title {
      font-size: 30px;
    }
  
    .title img {
      height: 30px;
      margin: 15px;
    }
  }
  
  .boxes img {
    max-width: 70%;
    max-height: 70%;
  }
  
  .title img {
    padding: 10px 10px;
    height: 35px;
    background-color: #ffff;
    border-radius: 20px;
    margin: 1px;
  }
  .end{
    font-size: 20px;
    color:#b7b9ba;
    font-weight: 400;
    background-color: #26ffcc2b;
    border-radius: 9px;
    padding: 5px;
}